<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
            ref="document"
            id="sell-order"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Orden de Venta
                    </h3>
                  </div>
                    <h3 class="">
                      {{user.companyName}}
                    </h3>
<!--                  <b-card-text class="mb-25">-->
<!--                    TEST-->
<!--                  </b-card-text>-->
<!--                  <b-card-text class="mb-25">-->
<!--                    TEST-->
<!--                  </b-card-text>-->
<!--                  <b-card-text class="mb-0">-->
<!--                    +52 (811) 456 7891, +52 (551) 543 2198-->
<!--                  </b-card-text>-->
                </div>


                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div v-if="false" class="d-flex justify-content-end mb-1">
                    <span class="title">
                      <h4>
                        Orden
                      <span class="invoice-number">#1</span>
                      </h4>
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Fecha de creación:
                    </span>
                    <flat-pickr
                      v-model="createdAtPicker"
                      class="form-control invoice-edit-input"
                      v-on:input="detectUnsavedChange"
                      :disabled="disableButtons"
                    />
                    <!-- FIXME ajustar el configTime -->
                      <!-- :config="configTime" -->
                  </div>
                  <div class="d-flex align-items-center" v-if="false">
                    <span class="title">
                      Fecha de entregado:
                    </span>
                    <flat-pickr
                      v-model="sellOrder.deliveredDate"
                      class="form-control invoice-edit-input"
                      v-on:input="detectUnsavedChange"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Cliente:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    :options="buyerCompanies"
                    label="name"
                    :reduce="option => option.companyId"
                    input-id="invoice-data-client"
                    :clearable="false"
                    v-on:input="(companyId) => {
                      detectUnsavedChange
                      getPriceListByBuyerCompanyIdApi(companyId);
                    }"
                    placeholder="Selecciona un cliente"
                    v-model="sellOrder.buyerCompanyId"
                    :disabled="disableButtons"
                  />
                </b-col>

                <!-- Col: Client Details -->
                <b-col
                  v-if="false"
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block companyInfo"
                  style="justify-content: center!important;"
                >
                <!-- Selected Client -->
                  <div
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      Empresa 1
                    </h6>
                    <b-card-text class="mb-25">
                      Direccion, Estado, Pais
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Telefono
                    </b-card-text>
                    <b-card-text class="mb-0">
                      Correo
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Item Section -->
            <b-card-body
              v-if="items.length > 0"
              class="invoice-padding form-item-section"
            >
              <div
                ref="form"
                class="repeater-form"
                :style="{height: 10}"
              >
                <b-row
                  v-for="(sellOrderItem, sellOrderIndexItem) in sellOrder.items"
                  :key="sellOrderIndexItem"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cantidad (kgs)
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Precio
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Subtotal
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <!-- Item ROW -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-1">
                        <!-- METAL -->
                        <b-col lg="6" sm="12" id="metal-select">
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            :options="items"
                            label="name"
                            :clearable="false"
                            class="item-selector-title"
                            placeholder="Selecciona un item"
                            :reduce="option => option.companyProductCatalogId"
                            v-model="sellOrderItem.companyProductCatalogId"
                            @input="companyProductCatalogId => setProductPrice(sellOrderItem, companyProductCatalogId)"
                            :disabled="disableButtons"
                          >
                          </v-select>
                        </b-col>
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Cantidad(kgs)</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            type="number"
                            :formatter="limitAmount"
                            v-model="sellOrderItem.quantity"
                            :disabled="disableButtons"
                            @input="quantity => sellOrderItem.subtotal = new BigNumber(sellOrderItem.price * quantity).toFormat(2)"
                          />

                        </b-col>
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Precio</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            type="number"
                            :formatter="limitAmount"
                            v-model="sellOrderItem.price"
                            :disabled="disableButtons"
                            @input="price => sellOrderItem.subtotal = new BigNumber(price * sellOrderItem.quantity).toFormat(2)"
                          />
                        </b-col>
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Subtotal</label>
                          <b-form-input
                            readonly
                            v-model="sellOrderItem.subtotal"
                          />
                        </b-col>
                      </b-row>
                      <div v-if="!disableButtons" class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="sellOrder.items.splice(sellOrderIndexItem, 1);"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="sellOrder.items.push({
                    companyProductCatalogId: undefined,
                    name: undefined,
                    price: BigNumber(0).toString(),
                    quantity: BigNumber(0).toString(),
                    subtotal: BigNumber(0).toString()
                })"
                id="add-item"
                :disabled="disableButtons"
              >
                Agregar item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body
              v-if="items.length > 0"
              class="invoice-padding pb-0"
            >
              <b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing">

                <!-- Col: Payment Type -->
                <b-col
                  v-if="false"
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                >
                <b-card-body class="invoice-padding pt-0">
                  <b-form-group
                    label="Tipo de pago"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="paymentTypes"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="option => option.code"
                      placeholder="Selecciona un tipo de pago"
                      v-model="selectedPaymentType"
                      :disabled="disableButtons"
                    />
                  </b-form-group>
                  <div
                    v-if="selectedPaymentType === 'CREDIT'"
                  >

                    <b-form-group
                      label="Dias de credito"
                      label-for="i-c-paymentType"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        type="number"
                        class="mb-2"
                        v-model="creditDays"
                        :disabled="disableButtons"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Fecha estimada de pago"
                      label-for="i-c-paymentType"
                      v-if="selectedPaymentType === 'CREDIT'"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        disabled
                        class="mb-2"
                        v-model="$moment().add(creditDays || 0, 'days').format('D/MM/YYYY')"
                      />
                    </b-form-group>
                  </div>
                </b-card-body>
                </b-col>

                <!-- Col: Status -->
                <b-col
                  v-if="false"
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                >
                <b-card-body class="invoice-padding pt-0">
                  <b-form-group
                    label="Estatus del pago"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="paymentStatus"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="option => option.code"
                      :disabled="selectedPaymentType === 'CONTADO' || disableButtons"
                      v-model="selectedPaymentStatus"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Cantidad abonada"
                    label-for="i-c-paymentType"
                    v-if="selectedPaymentStatus === 'PARTIALLY_PAID'"
                  >
                    <b-form-input
                      @keypress="restrictNumber($event)"
                      class="mb-2"
                      v-model="paymentAuthorization.amount"
                      @input="(amount)=> {
                          paymentAuthorization.partialAmountPaid = amount
                          paymentAuthorization.totalAmountDue = 0
                      }"
                      :disabled="disableButtons"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Estatus del envio"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="deliveryStatuses"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="option => option.code"
                      :disabled="disableButtons"
                      v-model="selectedDeliveryStatus"
                      @input="(status) => {
                          if (status !== 'DELIVERED') sellOrder.estimatedDeliveryDate = undefined
                          else sellOrder.estimatedDeliveryDate = this.$moment().format('D/MM/YY')
                      }"
                    />
                  </b-form-group>

                  <b-form-group
                          label="Fecha de entrega estimada"
                          label-for="i-c-paymentType"
                  >
                      <b-form-input
                          @keypress="restrictNumber($event)"
                          disabled
                          class="mb-2"
                          v-model="sellOrder.estimatedDeliveryDate"
                          :disabled="disableButtons"
                      />
                  </b-form-group>
                </b-card-body>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="12"
                  class="mt-md-6 d-flex justify-content-center"
                >
                  <div class="invoice-total-wrapper" style="max-width: 13rem">
<!--                    <div-->
<!--                      v-if="invoiceData.balancesCommissions.length > 0"-->
<!--                    >-->
<!--                      <b>Comisionistas</b>-->
<!--                      <div-->
<!--                      v-for="(balance, indexBalance) in invoiceData.balancesCommissions"-->
<!--                      :key="indexBalance"-->
<!--                      class="invoice-total-item">-->
<!--                        <p class="invoice-total-title">-->
<!--                          {{ balance.name }}-->
<!--                        </p>-->
<!--                        <p class="invoice-total-amount">-->
<!--                          $ {{calculatedValues(balance.idCommissionAgent, null, 'balancesCommissions')}}-->
<!--                        </p>-->
<!--                      </div>-->
<!--                      <hr class="my-50">-->
<!--                    </div>-->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{getSellOrderSubTotal()}}
                      </p>
                    </div>
                    <div class="invoice-total-item" v-if="false">
                      <p class="invoice-total-title">
                        Costo logísitca:
                      </p>
                      <p class="invoice-total-amount">
                        $ 0.00
                      </p>
                    </div>
<!--                    <div class="invoice-total-item">-->
<!--                      <p class="invoice-total-title">-->
<!--                        Comisiones:-->
<!--                      </p>-->
<!--                      <p class="invoice-total-amount">-->
<!--                        $ {{calculatedValues(null, null, 'commissions')}}-->
<!--                      </p>-->
<!--                    </div>-->
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        IVA (16%):
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'iva')}}
                      </p>
                    </div> -->
                    <div v-if="paymentAuthorization.amount > 0">
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b>Abonado:</b>
                        </p>
                        <p class="invoice-total-amount">
                          -$ {{paymentAuthorization.amount}}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b>Restante:</b>
                        </p>
                        <p class="invoice-total-amount">
                          $ {{new BigNumber(this.sellOrder.subtotal).minus(this.paymentAuthorization.amount).toFormat(2)}}
                        </p>
                      </div>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{getSellOrderTotal()}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Note -->
            <b-card-body
              v-if="items.length > 0"
              class="invoice-padding pt-0"
            >
              <span class="font-weight-bold">Nota: </span>
              <b-form-textarea
                v-on:input="detectUnsavedChange"
                v-model="sellOrder.note"
                :disabled="disableButtons"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="12"
        xl="12"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card style="padding-top: 0.8em">
          <!-- Action Buttons -->
          <b-row>
            <!-- Button: Save -->
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mb-75"
                block
                @click="showModal"
                :disabled="saveButtonStatus || disableButtons"
                :variant="'primary'"
              >
                <div v-if="saveButtonStatus">
                  <b-spinner small/>
                  &nbsp; Guardando orden...
                </div>
                Guardar orden
              </b-button>
            </b-col>
            <!-- Button: Print -->
            <b-col md="4" v-if="false">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="'primary'"
                class="mb-75"
                block
                :disabled='false'
                @click=""
              >
                Imprimir
              </b-button>
            </b-col>
            <!-- Button: Voucher -->
            <b-col md="4" v-if="false">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="'primary'"
                block
                v-b-toggle.voucher-options
                :disabled='false'
              >
                Enviar comprobante
              </b-button>
            </b-col>
          </b-row>
          <!-- Send Mail or SMS -->
          <b-collapse id="voucher-options" v-if="false">
            <b-row>
              <b-col lg="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  block
                  @click=""
                >
                  <div v-if="mailVoucherStatus">
                    <feather-icon
                      icon="MailIcon"
                      size="14"
                    />
                    Volver a abrir correo
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="MailIcon"
                      size="14"
                    />
                    Enviar por correo
                  </div>
                </b-button>
              </b-col>
              <b-col lg="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  block
                  @click=""
                  :disabled="smsVoucherStatus || smsSuccess"
                >
                  <div v-if="smsSuccess">
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="14"
                    />
                    SMS Enviado
                  </div>
                  <div v-else-if="smsVoucherStatus">
                    <b-spinner small/>
                    &nbsp; Enviando SMS...
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="14"
                    />
                    Enviar por SMS
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
    <!-- MODAL ALERTA -->
    <b-modal  id="missingParametersDialog"
      title="Alerta"
      ok-title="Voy a revisar"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <span style="white-space: pre-line">Favor de llenar los campos requeridos faltantes:
          {{ missingParameters }}</span>
        </div>
      </b-alert>
    </b-modal>
    <!-- MODAL CONFIRMACIÓN -->
    <b-modal id="saveOrderDialog"
      title="Confirmar"
      ok-title="Guardar orden"
      cancel-title="Voy a revisar"
      cancel-variant="outline-secondary"
      @ok="createSellOrder()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <h3 style="margin-bottom: 1em">¿Seguro que deseas guardar esta orden?</h3>
      <p style="font-size: medium">Los datos ingresados no podrán ser editados.</p>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.false-state {
  border-radius: 6px;
  border: 1px solid #f66;
  color: #f66;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner, BCollapse,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText,
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BigNumber from "bignumber.js";
import {v4 as uuidv4} from "uuid";



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BCollapse
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      buyerCompanies: [],
      createdAtPicker: new Date(),
      sellOrder: {
          sellOrderId: null,
          buyerCompanyId: null,
          sellerCompanyId: null,
          iva: this.$BigNumber(0).toString(),
          subtotal: this.$BigNumber(0).toString(),
          total: this.$BigNumber(0).toString(),
          note: "",
          status: "CREATED",
          items: [{
              companyProductCatalogId: undefined,
              name: undefined,
              price: this.$BigNumber(0).toString(),
              quantity: this.$BigNumber(0).toString(),
              subtotal: this.$BigNumber(0).toString()
          }],
          createdAt: undefined,
          modifiedAt: undefined,
          estimatedDeliveryDate: undefined,
          deliveredDate: undefined,
      },
      paymentAuthorization: {
          paymentAuthorizationId: null,
          status: "CREATED",
          paymentMethod: "CASH",
          sellOrderId: null,
          amount: this.$BigNumber(0).toNumber(),
          partialAmountPaid: this.$BigNumber(0).toNumber(),
          totalAmountDue: this.$BigNumber(0).toNumber(),
          createdAt: undefined,
          modifiedAt: undefined,
      },
      paymentTypes: [
          {
              code: 'FULL',
              name: 'Contado'
          },
          {
              code: 'CREDIT',
              name: 'Crédito'
          }
      ],
      paymentStatus: [
          {
              code: 'NOT_PAID',
              name: 'Sin pagar'
          },
          {
              code: "PARTIALLY_PAID",
              name: 'Parcialmente Pagado'
          },
          {
              code: "PAID",
              name: 'Pagado'
          }
      ],
      deliveryStatuses: [
          {
              code: "NOT_DELIVERED",
              name: 'Sin enviar'
          },
          {
              code: "IN_TRANSIT",
              name: 'En curso'
          },
          {
              code: "DELIVERED",
              name: 'Entregado'
          }
      ],
      selectedPaymentStatus: undefined,
      selectedPaymentType: undefined,
      selectedDeliveryStatus: undefined,
      creditDays: 0,


      // Catalogs
      items: [],

      // Props
      saveButtonStatus: false,
      byPassRouteLeave: true,
      disableButtons: false,
      mailVoucherStatus: false,
      smsVoucherStatus: false,
      smsSuccess: false,
      missingParameters: '',

      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only
      },

      }
  },
  created() {
      this.findCompaniesBuyerApi();
  },
  beforeRouteLeave(to, from, next) { // Evitar que se salgan de la pantalla y perder datos
    if (!this.saveButtonStatus && !(this.byPassRouteLeave)) {
      const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    findCompaniesBuyerApi() {
      this.$axios
          .post(this.$services + 'manager/findCompaniesBuyerApi', {
              companyId: this.user.companyId
          })
          .then((res) => {
              this.buyerCompanies = res.data;
          })
    },
    getPriceListByBuyerCompanyIdApi(companyId) {
        this.$axios.post(this.$services + "manager/getPriceListByBuyerCompanyIdApi", {
            companyId
        }).then(res => {
            this.items = res.data;
            if (this.items.length === 0)
                this.showToast('danger', 'Lista vacia, porfavor agregue ' +
                'productos a la lista de precios de venta');
        }).catch(error => {
            if (typeof error != 'string') {
            } this.showToast('danger', String(error));
        }).finally(() => {});
    },
    setProductPrice(item, companyProductCatalogId){
      const product = this.items.find(item => item.companyProductCatalogId === companyProductCatalogId )
      item.price = product.price
    },
    getSellOrderSubTotal() {
        let subtotal = new this.$BigNumber(0)
        for (const item of this.sellOrder.items) {
            const itemSubtotal = new this.$BigNumber(item.subtotal.replace(",",""))
            subtotal = subtotal.plus(itemSubtotal)
        }
        this.sellOrder.subtotal = subtotal.toFormat(2)
        return this.sellOrder.subtotal;
    },
    getSellOrderTotal(){
        // todo use number and just to display use bigNumber
        let total = new this.$BigNumber(this.sellOrder.subtotal.replace(",",""))
        total = total.minus(this.paymentAuthorization.amount || 0).plus(0).toFormat(2);
        this.sellOrder.total = total
        return this.sellOrder.total
    },
    showModal() {
      if (this.verifySellOrderValues()) {
        this.$bvModal.show('missingParametersDialog');
        this.saveButtonStatus = false;
        return;
      }

      this.$bvModal.show('saveOrderDialog');
    },
    createSellOrder() {
        const currentDate = new Date()
        const createdAt = this.$moment(this.createdAtPicker)

        // TODO review if the createdAt can be modified
        if (this.$moment().isSame(createdAt, 'day')) {
            this.sellOrder.createdAt = currentDate;
            this.sellOrder.modifiedAt = currentDate;
            this.sellOrder.estimatedDeliveryDate = currentDate;
            this.sellOrder.deliveredDate = currentDate;

            this.paymentAuthorization.createdAt = currentDate;
            this.paymentAuthorization.modifiedAt = currentDate;
        } else {
            const date = new Date(createdAt);
            date.setHours(0, 0, 0, 0);
            this.sellOrder.createdAt = date
            this.sellOrder.modifiedAt = date
            this.sellOrder.estimatedDeliveryDate = date;
            this.sellOrder.deliveredDate = date;

            this.paymentAuthorization.createdAt = currentDate;
            this.paymentAuthorization.modifiedAt = currentDate;
        }



        const buyerCompanyName = (this.buyerCompanies.find(
            buyerCompany => buyerCompany.companyId === this.sellOrder.buyerCompanyId)).name

        this.sellOrder.sellOrderId = uuidv4();
        this.sellOrder.shortSellOrderId = this.uuidToShortUuid(this.sellOrder.sellOrderId)
        this.sellOrder.sellerCompanyId = this.user.companyId;
        this.sellOrder.sellerCompanyName = this.user.companyName
        this.sellOrder.buyerCompanyName = buyerCompanyName;
        this.sellOrder.userId = this.user.userId;

        if (typeof this.sellOrder.iva === 'string')
          this.sellOrder.iva = new this.$BigNumber(this.sellOrder.iva.replace(",","")).toNumber()
        if (typeof this.sellOrder.total === 'string')
          this.sellOrder.total = new this.$BigNumber(this.sellOrder.total.replace(",","")).toNumber()
        if (typeof this.sellOrder.subtotal === 'string')
          this.sellOrder.subtotal = new this.$BigNumber(this.sellOrder.subtotal.replace(",","")).toNumber()

        for (const item  of this.sellOrder.items) {
            if (typeof item.subtotal === 'string')
              item.subtotal = new this.$BigNumber(item.subtotal.replace(",","")).toNumber()
        }


        this.paymentAuthorization.paymentAuthorizationId = uuidv4();
        this.paymentAuthorization.sellOrderId = this.sellOrder.sellOrderId;
        this.paymentAuthorization.companyId = this.user.companyId;
        this.paymentAuthorization.userId = this.user.userId;
        this.paymentAuthorization.amount = this.sellOrder.total
        this.paymentAuthorization.partialAmountPaid = this.sellOrder.total

        this.createSellOrderApi({
            sellOrder: this.sellOrder,
            paymentAuthorization: this.paymentAuthorization
        })
    },
    createSellOrderApi(order) {
        this.saveButtonStatus = true;
        this.disableButtons = true;
        this.$axios
            .post(this.$services + "manager/createSellOrderApi", {
                sellOrder: order.sellOrder,
                paymentAuthorization: order.paymentAuthorization,
            })
            .then((res) => {
                if (!res.data.error) {
                    this.showToast("success", String("¡Ticket cerrado con éxito!"));
                }
                router.push({ name: 'sell-orders' })
            })
            .catch((error) => {
                // let newUnsavedOrders = this.getDataFromLocalStorage("unsavedOrders")
                // newUnsavedOrders.push(order)
                // this.removeDataFromLocalStorage("unsavedOrders")
                // this.addDataToLocalStorage("unsavedOrders", newUnsavedOrders)

                // this.latestPurchaseOrders.unshift(order.purchaseOrder);
                // this.addDataToLocalStorage("latestPurchaseOrders", this.latestPurchaseOrders)

                this.showToast("danger", String("Falla en el internet, orden no guardada"));
            })
            .finally(() => {
                this.saveButtonStatus = false;
                this.disableButtons = false;
            });
    },
    verifySellOrderValues() {
      let missing = false;
      this.missingParameters = '\n\n';


      if (this.sellOrder.buyerCompanyId == null) {
        missing = true;
        this.missingParameters += '• Cliente\n';
      }

      if (this.sellOrder.items.length === 0) {
        missing = true;
        this.missingParameters += '• Agregar al menos un item \n';
      }

      for (const [index, item] of this.sellOrder.items.entries()) {
        if (!item.companyProductCatalogId) {
          missing = true;
          this.missingParameters += '• Nombre del item núm. ' + (index+1) + '\n';
        }

        if (!item.quantity) {
          missing = true;
          this.missingParameters += '• Cantidad del item núm. ' + (index+1) + '\n';
        }

        if (!item.price) {
          missing = true;
          this.missingParameters += '• Precio del item núm. ' + (index+1) + '\n';
        }
      }

      return missing;
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event) { // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.byPassRouteLeave = false;
    },
    limitAmount(e) {
      if (parseInt(e) < 0)
        return 0;
      else
        return e;
    },
    uuidToShortUuid(uuid) {
        return uuid.slice(0,8)
    },
  },
  computed: {
      BigNumber() {
          return BigNumber
      }
  },
}
</script>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  #add-item {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .companyInfo {
    display: flex !important;
  }

  // Items
  #metal-select {
    flex: 0 0 auto;
    width: 50%;
  }

  .item-data {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  #spacer {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .item-commission {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

}
</style>
